import React from "react";
import { Navigation } from "../components/navigation";
import { Gallery } from '../components/gallery';


const Gallerys = () => {
    return ( 
        <div>
        <Navigation/>
        <Gallery/>
       </div>
    );
  };
  
 export default Gallerys;