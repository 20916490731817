import React from "react";
import "../css/gallery.css";

export const Gallery = (props) => {
  return (
    <body class="bodyGallery">
      <div class="grid-container" style={{ marginTop: 140 }}>
        <div>
          <img
            class="grid-item grid-item-1"
            img
            src="/img/Galerija/0.jpg"
            alt="galerija"
          />
          
        </div>
        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0a.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0b.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0c.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0d.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/12.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/13.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/14.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/15.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/16.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0l.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/18.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/30.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/31.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/32.jpg"
            alt="galerija"
          />
        </div>
        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/33.jpg"
            alt="galerija"
          />
        </div>
        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/34.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/35.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/36.jpg"
            alt="galerija"
          />
        </div>


        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/37.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/1.jpg"
            alt="galerija"
          />
        </div>
        <div>
          <img
            class="grid-item grid-item-3"
            src="/img/Galerija/3.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/5.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/6.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/7.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/8.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/9.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/10.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/11.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/1.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0e.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0f.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0g.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0h.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0i.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0j.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0k.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/19.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/20.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/21.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/22.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/23.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/24.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/25.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/26.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/27.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/28.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/29.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0m.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0n.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0o.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0p.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0r.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/0s.jpg"
            alt="galerija"
          />
        </div>

       
        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/38.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/39.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/40.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/41.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/42.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/43.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/44.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/45.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/46.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/47.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/48.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/49.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/50.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/51.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/52.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/53.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/54.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/55.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/56.jpg"
            alt="galerija"
          />
        </div>

        <div>
          <img
            class="grid-item grid-item-2"
            img
            src="/img/Galerija/57.jpg"
            alt="galerija"
          />
        </div>
      </div>
    </body>
  );
};
