import React from "react";
import { Navigation } from "../components/navigation";
import News from "../components/news";


const NewsPage = () => {
    return ( 
        <div>
        <Navigation/>
        <News/>
       </div>
    );
  };
  
 export default NewsPage;