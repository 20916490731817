import React from "react";
import SingleNews from "../components/singleNews";


const SingleNewsPage = () => {
    return ( 
        <div>
        <SingleNews/>
       </div>
    );
  };
  
 export default SingleNewsPage;